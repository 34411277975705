import React from "react";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import Live from "./page/live";
import Completed from "./page/completed";
import InvalidMeeting from "./page/error";





const router = createBrowserRouter([
  {
    path: "/",
    element: <Live />,
  },
  {
    path: "completed",
    element: <Completed />,
  },
  {
    path: "invalid-meeting",
    element: <InvalidMeeting />,
  },
]);


function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;