import React, { useEffect, useState } from "react"; 
import "./App.css";
import LoadingOverlay from "react-loading-overlay";
import moment from "moment";
import { redirect, Navigate } from "react-router-dom";



export default function Live() {
  const searchParams = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(false);
  const [loadingTxt, setLoadingTxt] = useState("Initializing...");
  const [hasError, setHasError] = useState(false);
  // const [meetingStarted, setMeetingStarted] = useState(false);

  // const [tokenError, setTokenError] = useState(false);

  const [credentials, setCredentials] = useState({
    meetingid: "",
    password: "",
    registrantToken: "",
    sdkey: "",
    zaktoken: "",
    userEmail: "",
    userName: "",
    tk: "",
  });
  const schedule_id = searchParams.get("schedule_id");
  const bearerToken = searchParams.get("token");
  const gm = searchParams.get("id");
  const authEndpoint = process.env.REACT_APP_API_URL + "/zoom/signature";
  const leaveUrl = process.env.REACT_APP_ROOT_URL+"/completed";

  // useEffect(() => {
  //   window.addEventListener("beforeunload", alertUser);
  //   return () => {
  //     window.removeEventListener("beforeunload", alertUser);
  //   };
  // });

  // const alertUser = (event) => {
  //   event.preventDefault();
  //   event.returnValue = "";
  // };

  useEffect(() => {
    setLoading(true);
    // const idd = localStorage.getItem("gp-xyz");
    // setTokenError(false);
    // if (idd == null || idd == undefined || idd == gm) {
    //   //alert(idd);
    //   setTokenError(false);
    //   validateUser();
    // } else {
    //   setHasError(true);
    //   setTokenError(true);
    //   setLoading(false);
    // }
    validateUser();
  }, []);

  function validateUser() {
    fetch(process.env.REACT_APP_API_URL + "/zoom/mobile-validate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken,
      },
      body: JSON.stringify({
        schedule_id: schedule_id,
        gm_id: gm,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoadingTxt("Validating User...");

        if (response.status == 200) {
          //  localStorage.setItem("gp-xyz", gm);
          updateCredentials(response.results);
          setHasError(false);
        } else {
          setLoading(false);
          setHasError(true);
          alert("Validating User error")
          //    alert(JSON.stringify(response))

        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        setHasError(true);
        alert("Validating User api error")

      });
  }

  function updateUserSpendTime(tm) {
    fetch(process.env.REACT_APP_API_URL + "/zoom/user-online", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken,
      },
      body: JSON.stringify({
        schedule_id: schedule_id,
        tx: tm,
      }),
    })
      .then((res) => res.json())
      .then((response) => { })
      .catch((error) => {
        console.error(error);
      });
  }

  function updateCredentials(signedToken) {
    let hasError = false;
    let xyz = {};
    let result;
    try {
      result = atob(signedToken);
      xyz = JSON.parse(result);
      console.log(xyz);
      setCredentials(xyz);
    } catch (e) {
      hasError = true;
      console.log(e);
    }

    setLoading(false);

    if (hasError) return;
  }

  function getSignature(e) {
    e.preventDefault();
    setLoadingTxt("Initializing SDK...");
    setLoading(true);

    import("@zoomus/websdk")
      .then(({ ZoomMtg }) => {
        ZoomMtg.setZoomJSLib("https://source.zoom.us/2.12.0/lib", "/av");

        ZoomMtg.preLoadWasm();
        ZoomMtg.prepareWebSDK();
        // loads language files, also passes any error messages to the ui
        ZoomMtg.i18n.load("en-US");
        ZoomMtg.i18n.reload("en-US");

        ZoomMtg.inMeetingServiceListener("onUserJoin", (data) => {
          console.log("inMeetingServiceListener onUserJoin", data);
        });

        setLoadingTxt("Validating Meeting...");

        fetch(authEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + bearerToken,
          },
          body: JSON.stringify({
            schedule_id: schedule_id,
          }),
        })
          .then((res) => res.json())
          .then((response) => {
            setLoadingTxt("Starting Meeting...");

            startMeeting(response.results.signature, ZoomMtg);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        setLoading(false);
        setLoadingTxt("------------------");
      });
  }

  function startMeeting(signature, ZoomMtg) {
    document.getElementById("zmmtg-root").style.display = "block";

    const meetingConfig = {
      signature: signature,
      sdkKey: credentials.sdkey,
      meetingNumber: credentials.meetingid,
      passWord: credentials.password,
      userName: credentials.userName,
      userEmail: credentials.userEmail,
      tk: credentials.tk,
      zak: credentials.zaktoken,
    };

    ZoomMtg.init({
        debug: true,
      leaveUrl: leaveUrl,
      success: (success) => {
        setLoading(false);
        // setMeetingStarted(true);
        console.log(success);

        const intervalId = setInterval(() => {
          updateUserSpendTime(moment.utc().format());
        }, 30000);

        ZoomMtg.join({
          ...meetingConfig,
          success: (success) => {
            console.log(success);
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  if(hasError) {

      return <Navigate to="/invalid-meeting" replace />;

}

  return (
    <div className="App">
      <LoadingOverlay
        active={loading}
        spinner
        text={loadingTxt}
        styles={{
          overlay: (base) => ({
            ...base,
            // background: "rgba(255, 0, 0, 0.5)",
            position: "fixed",
          }),
        }}
      >
        <main>
          {hasError ? (
            <h1>Invalid meeting</h1>
          ) : (
            <button onClick={getSignature}>Join Meeting</button>
          )}
        </main>
      </LoadingOverlay>
    </div>
  );
}