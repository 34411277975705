import React from 'react';
import './style.css';

const Completed = () => {
  const handleCloseTab = () => {
    window.close();
  };

  return (
    <div className="completed-container" onClick={handleCloseTab}>
      <h1 className="completed-heading" >
        Meeting completed
      </h1>
    </div>
  );
};

export default Completed;