import React from 'react';

import './style.css';

function InvalidMeeting() {
  return (
    <div className="invalid-meeting">
      <h1>Invalid Meeting / completed</h1>
      <p>Please check your meeting details and try again.</p>
    </div>
  );
}

export default InvalidMeeting;